<script>

import { store } from "./store.js";
import axios from "axios";
import Navigation from "../src/components/Navigation.vue";
import Footer from "../src/components/Footer.vue";
import hotspotMixin from "./utils/mixins.js";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.baseURL = import.meta.env.VITE_AXIOS_BASE_URL;
axios.defaults.withCredentials = true;
axios.defaults.timeout = import.meta.env.VITE_AXIOS_TIMEOUT;
// axios.defaults.headers.common['Authorization'] = import.meta.env.VITE_AXIOS_PORTAL_API_AUTH_KEY

export default {
  mixins: [hotspotMixin],
  components: { Navigation, Footer },
  data: () => ({
    store,
    message: "",
    expand: false,
    showDrawer: false,
    connectionRefused: false,
    loggingOut: false,
    width: "",
    isSmall: true,
    activeLink: null,
  }),

  methods: {
    async logout() {
      this.loggingOut = true;
      let vm = this;
      let response = await axios
        .get("logout/")
        .then(function (response) {
          if (response.status === 200) {
            vm.store.isUserAuthenticated = false;
            vm.loggingOut = false;
            vm.logoutFromHotspot();
            vm.store.referral_user = null;
            vm.$router.push({ name: "Login" });
          }
        })
        .catch((error) => {
          if (!error.response) {
            vm.connectionRefused = true;
          }
          vm.loggingOut = false;
        });
    },

    async logoutFromHotspot() {
      this.loggingOut = true;
      let vm = this;
      let response = await axios
        .get("https://hotspot.toomuchwifi.co.za/logout.html", {
          withCredentials: false,
        })
        .then(function (response) {

        })
        .catch((error) => {

        });

      vm.loggingOut = false;
    },



    toggleHotspotDebug() {
      this.hotspot_data = {
        "identity": "node-0400",
        "logged-in": "yes",
        "username": "0747202605"
      }

      this.hotspot_connected = true;
    },

    refreshPage() {
      this.connectionRefused = false;
      this.hotspotDetect();
    },
    accessWallet() {

      let url = "https://wallet.toomuchwifi.co.za";
      window.location.href = url;
    },


    displayWindowSize() {
      // Get width and height of the window excluding scrollbars
      this.width = document.documentElement.clientWidth;
      if (this.width < 500) {
        this.isSmall = true

      }
      else {
        this.isSmall = false
        this.showDrawer = false
      }

    },
  },
  watch: {
    screenSize(width) {
      this.width = screen.width

    }
  },
  mounted() {
    // this.hotspotDetect();

  },
  created() {
    window.addEventListener("resize", this.displayWindowSize);
    this.displayWindowSize()
  }
};


// (function (d, t) {
//   var BASE_URL = "https://chatwoot.toomuchwifi.co.za";
//   var g = d.createElement(t), s = d.getElementsByTagName(t)[0];
//   g.src = BASE_URL + "/packs/js/sdk.js";
//   g.defer = true;
//   g.async = true;
//   s.parentNode.insertBefore(g, s);
//   g.onload = function () {
//     window.chatwootSDK.run({
//       websiteToken: '7JM8f5oRBz6F91hBuDndN2CM',
//       baseUrl: BASE_URL
//     })
//   }
// })(document, "script");

</script>

<template>
  <v-app>


    <Navigation @logout="logout" />
    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- Provides the application the proper gutter -->

      <!-- If using vue-router -->
      <div v-if="connectionRefused" class="text-center">
        <v-row no-gutters>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="mx-auto pt-16">
            <v-icon size="100">mdi-emoticon-sad-outline</v-icon>
            <p class="page-title pb-10">
              Oops! Connection Lost
            </p>
            <p>Please check your internet connection and try again</p>
            <v-btn @click="refreshPage" size="large" class="font-weight-bold mt-12 bg-pacificBlue" rounded="medium">Try
              Again</v-btn>
          </v-col>
        </v-row>
      </div>

      <router-view v-else v-slot="{ Component, route }">

      </router-view>


    </v-main>
    <Footer />

  </v-app>
</template>

<style>

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');
html {
  font-family: 'Montserrat', sans-serif;
}

a {
  text-decoration: none;
  cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.flex-wrapper {
  display: flex;
  min-height: 85vh;
  flex-direction: column;
  justify-content: space-between;
}

.app-container {
  max-width: 1280px;
  padding: 0 1em;
  width: 100%;
  margin: auto;
}

.page-title {
  padding-top: 40px;
  padding-bottom: 80px;
  font-size: 22px;
  font-weight: 400;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 450px;
}

.img {
  width: 100%;
}

/* .img {
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  border-radius: 69.565px;
  border: 2.783px solid #E6E6E6;
  background: url(<path-to-image>), lightgray 0px -4.667px / 100% 177.778% no-repeat, #FFF;

} */

.icons {
  opacity: 1px;
}

.yellow-background {
  color: #F9B500 !important;
}

.white-background {
  color: white !important;
}

.horizontal-nav-bar {
  margin-right: 100px !important;
}

.v-list-item--active>.v-list-item__overlay,
.v-list-item[aria-haspopup=menu][aria-expanded=true]>.v-list-item__overlay {
  opacity: 0%;
}

.v-list-item--active>.v-list-item__content {
  color: #F9B500 !important;
}

.text-h4 {
  font-family: 'Montserrat', sans-serif !important;
}
.banner-container {
  width: 100%;

}

.banner-container img {
  width: 100%;
}

@media only screen and (min-width: 1280px) {
  .banner-container {
    width: 80%;
    margin: auto;
  }
}
</style>


<template>
  <button v-if="link" :class="[primary ? 'primary' : 'secondary', row ? 'row' : '']" :style="cssProps">
    <a :href="link">
      {{ text }}
      <v-icon v-if="icon" class="button-icon" :style="{'color': 'inherit', 'font-size': '1em'}" size="100">{{ icon }}</v-icon>
    </a>
  </button>
  <button v-else :style="cssProps" :class="[primary ? 'primary' : 'secondary', row ? 'row' : '']" :type="type" :disabled="disableButton" class="text-center">
    {{ text }}
    <v-icon v-if="icon" class="button-icon" :style="{'color': 'inherit', 'font-size': '1em'}" size="100">{{ icon }}</v-icon>
  </button>
</template>
<script>
export default {
  name: "ButtonComponent",
  props: {
    text: String,
    primary: Boolean,
    row: Boolean,
    link: String,
    color: String,
    margin: String,
    boxShadow: String,
    backgroundColor: String,
    margin: String,
    width: String,
    icon: String,
    width: String,
    type: String,
    disableButton: Boolean
  },
  data:() => ({
    // disableButton: this.disableButton
  }),
  // emits: ['update:disableButton'],
  mounted() {
    console.log(this.width, "disabled")
    console.log(this.backgroundColor, "disabled")
    console.log(this.row, "disabled")
  },
  // watch: {
  //   disableButton(newVal) {
  //     console.log(newVal)
  //     console.log(disableButton)
  //     this.$emit('update:disableButton', newVal)
  //   }
  // },
  computed: {
    cssProps() {
      return {
        "--space": this.margin,
        "--width": this.width,
        "--color": this.color,
        "--width": this.width,
        "--boxShadow": this.boxShadow,
        "--backgroundColor": this.backgroundColor,
      }
    }
  },
}
</script>
<style scoped>
  .row {
    margin: var(--space);
    width: 48% !important;
  }

  button {
    box-shadow: var(--boxShadow);
    width: 100%;
    margin: var(--space);
  }

  .primary {
    display: block;
    margin: var(--space);
    width: var(--width);
    border-radius: 3px;
    padding: 0.5em 1em;
    font-size: 1em;
    font-weight: 600;
    background-color: var(--backgroundColor);
    color: #FFFFFF;
    border: 2px solid;
  }

  .primary:hover {
    background-color: transparent;
    border: 2px solid;
    border-color: var(--backgroundColor);
    color: var(--backgroundColor);
  }

  .button-icon {
    font-size: 1em;
    color: inherit;
    height: auto !important;
    width: auto !important;
  }

  .secondary {
    display: block;
    margin: var(--space);
    width: var(--width);
    border: 2px solid;
    border-color: var(--backgroundColor);
    background-color: transparent;
    color: var(--backgroundColor);
    padding: 0.5em 0;
    font-weight: 600;
    font-size: 1em;
    font-weight: 600;
    /* box-shadow: 0px 2px 4px 0px rgba(24, 153, 209, 0.22); */
    border-radius: 3px;
  }

  a {
    color: inherit;
    width: 100%;
    display: block;
  }

  .secondary:hover {
    background-color: var(--backgroundColor);
    color: #FFFFFF;
  }

  button:disabled,
  .button[disabled]{
    opacity: .7;
    cursor: not-allowed;
  }
@media only screen and (max-width: 768px) {
  .row {
    margin: var(--space);
    width: 100% !important;
  }
}
</style>

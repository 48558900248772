import { reactive } from 'vue'
import welcome from "../src/assets/img/welcome.png";
import whatsapp from "../src/assets/img/whatsapp.svg";
import portal from "../src/assets/img/portal.svg";
import email from "../src/assets/img/email.svg";
import phone from "../src/assets/img/phone.svg";
import communication from "../src/assets/img/communication.png";
import card from "../src/assets/img/card.svg";
import eft from "../src/assets/img/eft.svg";
import payment from "../src/assets/img/payment.svg";
import debit from "../src/assets/img/debit.svg";
import hardware from "../src/assets/img/hardware.png";
import speed from "../src/assets/img/speed.png";
import superzone from "../src/assets/img/superzone.png";
import info from "../src/assets/img/info.png";
import question from "../src/assets/img/question.png";

const isDev = process.env.NODE_ENV === 'development';
var peachCheckout = ""
if (isDev) {

    peachCheckout = "https://testsecure.peachpayments.com/checkout"
}
else {
    peachCheckout = "https://secure.peachpayments.com/checkout"
}
export const store = reactive({
    customerDetails: null,
    uncappedBundles: null,
    isUserAuthenticated: null,
    cellphone: null,
    password: null,
    isConnectedToHotspot: null,
    isHotspotDetected: null,
    hotspotAuthenicated: null,
    peachCheckoutUrl: peachCheckout,
    cart: [],
    macAddress: null,
    loading: true,
    referral_user: [],
    referrer_list: [],
    serviceProviders: ["Kazang", "OTT", "shop2shop", "1FORYOU", "TooMuchWifi"],
    isTermsOfUseAccepted:false
})

export const hardwareCheckout = reactive({
    cart: [],
    lan: null,
    lot: null
})

export const onboardingData = reactive({
  slider: [
    {
      heading: "Welcome to TooMuchWifi",
      textColor: "#24292E",
      subheading: "",
      backgroundColor: "#FFFFFF",
      image: {
        url: welcome,
      },
      blurb:
        "<p style='text-align: left'>Welcome to TooMuchWifi! This is our user-friendly guide to the best internet service experience. In this guide, you will get a better understanding of the installation process, the various ways to pay for your TooMuchWifi connection, and how to contact us. We are so excited to have you onboard!</p>",
      extraText: "",
      list: [],
      questions: [],
      externalLink: "",
      important_info: "",
    },
    {
      // heading: "<h1 style='color: #DD281D;'>Create a ticket</h1>",
      heading: "Create a Ticket",
      textColor: "#24292E",
      subheading: "",
      backgroundColor: "#FFFFFF",
      image: {},
      blurb:
        "<p style='text-align: left'>If you need help with anything, create a ticket and one of our customer support agents will contact you. Here’s how:</p>",
      extraText: "",
      list: [
        {
          icon: {
            url: whatsapp,
          },
          heading: "Drop us a WhatsApp",
          textColor: "#24292E",
          blurb:
            "Send 'Hi' to our WhatsApp number and our customer support robot will give you steps to follow to fix the issue.<a href='https://wa.me/0600169434'><b>+27 (60) 016-9434</b></a>",
        },
        {
          icon: {
            url: portal,
          },
          heading: "Customer Portal",
          textColor: "#24292E",
          blurb:
            "Log into your TooMuchWifi account on our Customer Portal, and click on the customer support tab. You can also visit <a href='https://portal.toomuchwifi.co.za/customer-support'><b>https://portal.toomuchwifi.co.za/customer-support</b></a>",
        },
        {
          icon: {
            url: email,
          },
          heading: "Send us an Email",
          textColor: "#24292E",
          blurb:
            "You can reach out to us directly at <a href='mailto:contact@toomuchwifi.co.za'><b>contact@toomuchwifi.co.za</b></a>. Describe your query in as much detail as possible.",
        },
        {
          icon: {
            url: phone,
          },
          heading: "Give us a Call",
          textColor: "#24292E",
          blurb:
            "Call us directly on <a href=''><b>+27 (60) 016-9434</b></a>, listen to the prompts and one of our customer support agents will help you.",
        },
      ],
      questions: [],
      externalLink: "",
      important_info: "",
    },
    {
      heading: "Refer a Chommie and Save!",
      textColor: "#24292E",
      subheading: "",
      backgroundColor: "#FFFFFF",
      image: {
        url: communication,
      },
      blurb:
        "<p style='text-align: left'>Refer your chommie and get rewarded! Here’s how you can get your referral link to share with friends and family.</p>",
      extraText:
        "<p style='text-align: left'>Make sure they use your referral link in order for you to get the rewards. Terms and conditions apply. You can see all of your referrals by logging into our Customer Portal and visiting <a href='https://portal.toomuchwifi.co.za/referrals'><b>https://portal.toomuchwifi.co.za/referrals</b></a></p>",
      list: [
        {
          icon: {},
          heading: "",
          textColor: "#24292E",
          blurb:
            "1. Log into your portal and go to Refer Friends: <a href='https://portal.toomuchwifi.co.za/referrals/'><b>link</b></a>",
        },
        {
          icon: {},
          heading: "",
          textColor: "#24292E",
          blurb:
            "2. Click on your referral link and hold. Copy the link and send it to everyone you know!",
        },
        {
          icon: {},
          heading: "",
          textColor: "#24292E",
          blurb:
            "3. You can also enter your friends’ phone number and TooMuchWifi will send them your referral link ",
        },
      ],
      questions: [],
      externalLink: "<p style='text-align: left;'>Your referral code:</p>",
      important_info: "",
    },
    {
      heading: "Moolah Payments",
      textColor: "#24292E",
      subheading: "",
      backgroundColor: "#FFFFFF",
      image: {},
      blurb:
        "<p style='text-align: left'>We will send you a WhatsApp when your payment is due. Simply click the link in our message, and select one of these payment options:</p>",
      extraText:
        "<p style='text-align: left'>If you would prefer to make a physical payment, you can visit your nearest ATM and make a deposit. It is important to use your Node Number as your reference number.</p>",
      list: [
        {
          icon: {
            url: card,
          },
          heading: "Option 1: Card Payment",
          textColor: "#24292E",
          blurb:
            "The easiest way to pay is by card. Simply click on the payment link in our WhatsApp message and select 'card payment.'",
        },
        {
          icon: {
            url: eft,
          },
          heading: "Option 2: Instant EFT",
          textColor: "#24292E",
          blurb:
            "Paying by InstantEFT is fast and simple. Select the link in our WhatsApp message, and select 'InstantEFT' to make an immediate payment.",
        },
        {
          icon: {
            url: payment,
          },
          heading: "Option 3: Portal Payment",
          textColor: "#24292E",
          blurb:
            "Log into your Customer Account and pay through your portal! Select the Uncapped Account tab and choose your favourite questions of payment.",
        },
        {
          icon: {
            url: debit,
          },
          heading: "Option 4: Debit Order",
          textColor: "#24292E",
          blurb:
            "Fill out our Debit Order questions and choose which date you would like us to deduct your amount every month.",
        },
      ],
      questions: [],
      externalLink: "",
      important_info: "",
    },
    {
      heading: "Know your Hardware",
      textColor: "#24292E",
      subheading: "",
      backgroundColor: "#FFFFFF",
      image: {
        url: hardware,
      },
      blurb: "",
      extraText: "",
      list: [],
      questions: [],
      externalLink: "",
      important_info:
        "<p><b>Please note: If you cancel your account with us, we will arrange to collect our equipment.</b></p>",
    },
    {
      heading: "How to Log a Speed Test",
      textColor: "#24292E",
      subheading: "",
      backgroundColor: "#FFFFFF",
      image: {
        url: speed,
      },
      blurb: "<p style='text-align: left'>Steps on how to log a Speed Test</p>",
      extraText:
        "<p style='text-align: left'>For speed test issues or questions, contact our TooMuchWifi support team by logging a ticket.  We’re here to help!</p>",
      list: [
        {
          icon: {},
          heading: "1. Visit the Speed Test link",
          textColor: "#24292E",
          blurb:
            "Click <a href='https://speedtest.toomuchwifi.co.za/'><b>TooMuchWifi Speed Test</b></a>",
        },
        {
          icon: {},
          heading: "2. Start the Speed Test",
          textColor: "#24292E",
          blurb: "Click the ‘Start’ button to begin the Speed Test.",
        },
        {
          icon: {},
          heading: "3. Compare the Results",
          textColor: "#24292E",
          blurb:
            "After you have received the results, compare them to the TooMuchWifi package you have purchased.",
        },
      ],
      questions: [],
      externalLink: "",
      important_info: "",
    },
    {
      heading: "Connect to TooMuchWifi SuperZone Hotspot",
      textColor: "#24292E",
      subheading: "",
      backgroundColor: "#FFFFFF",
      image: {
        url: superzone,
      },
      blurb:
        "<p style='text-align: left'>Visit a TooMuchWifi Hotspot and connect to our SuperZone. Just grab your device, buy a voucher, and go online. It's easy to use, and you can top up anytime. <br /><br />You can find TooMuchWifi Hotspots in every community we are in, just look for our Hotspot logo.</p>",
      extraText: "",
      list: [
        {
          icon: {},
          heading: "",
          textColor: "#24292E",
          blurb: "1. Connect to TooMuchWifi Superzone",
        },
        {
          icon: {},
          heading: "",
          textColor: "#24292E",
          blurb: "2. Enter your cellphone number and password",
        },
        {
          icon: {},
          heading: "",
          textColor: "#24292E",
          blurb: "3. Load your voucher",
        },
        {
          icon: {},
          heading: "",
          textColor: "#24292E",
          blurb: "4. Enter the voucher code",
        },
        {
          icon: {},
          heading: "",
          textColor: "#24292E",
          blurb: "4. Enjoy your scrolling!",
        },
      ],
      questions: [],
      externalLink: "",
      important_info: "",
    },
    {
      heading: "Important Information",
      textColor: "#24292E",
      subheading: "",
      backgroundColor: "#FFFFFF",
      image: {
        url: info,
      },
      blurb: "",
      extraText: "",
      list: [
        {
          icon: {},
          heading: "Customer Support",
          textColor: "#24292E",
          blurb:
            "Monday - Saturday: 8:00 - 20:00 <br /> Sundays & Holidays: 09:00 - 17:00",
        },
        {
          icon: {},
          heading: "Create a Ticket",
          textColor: "#24292E",
          blurb:
            "<a href='https://portal.toomuchwifi.co.za/customer-support'>https://portal.toomuchwifi.co.za/customer-support</a>",
        },
        {
          icon: {},
          heading: "Give us a Call",
          textColor: "#24292E",
          blurb: "<a href='+27600169434'>+27 (60) 016-9434</a>",
        },
        {
          icon: {},
          heading: "Bank details",
          textColor: "#24292E",
          blurb:
            "Bank name: FNB <br /> Account name: TooMuchWifi(PTY) LTD <br /> 62747851039 | 250655 <br /> (NB: Use your NODE number as the reference)",
        },
        {
          icon: {},
          heading: "Your Node Number",
          textColor: "#24292E",
          blurb: "Always include it on your tickets & payments",
        },
      ],
      questions: [],
      externalLink: "",
      important_info: "",
    },
    {
      heading: "Question Time",
      textColor: "#24292E",
      subheading: "",
      backgroundColor: "#FFFFFF",
      image: {
        // url: question,
      },
      blurb: "",
      extraText: "",
      list: [],
      questions: [],
      externalLink: "",
      important_info: "",
    },
  ],
});

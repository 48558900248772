<script>
import axios from "axios";
import { store } from "../store.js";
import ButtonComponent from "./atoms/ButtonComponent.vue";
import hotspotMixin from "../utils/mixins.js";

import hotspotDoubleUpPath from "@/assets/img/hotspotDoubleUp.jpg";


export default {
  mixins: [hotspotMixin],
  components: { ButtonComponent },
  data: () => ({
    store,
    voucherCode: null,
    voucherCodeRules: [
      (v) => !!v || "Voucher Code is required"
    ],
    formErrors: "",
    validVoucher: null,
    buttonLoading: false,
    provider: null,
    eligibleForDoubleUp: false,
    hotspotDoubleUpPath,
  }),
  methods: {
    validateInput() {
      this.loadDeviceVoucher();
      // if (this.$refs.form.validate()) {

      // }
    },
    checkProvider(status) {
        console.log(status)
      switch (status) {
          case "Kazang":
          return "kazang";
          break;
          case "OTT":
          return "ott";
          break;
          case "shop2shop":
          return "shop2shop";
          break;
          case "1FORYOU":
          return "onevoucher";
          break;
          case "TooMuchWifi":
          return "toomuchwifi";
          break;
      }
    },
    loadDeviceVoucher() {
      let vm = this;
      this.buttonLoading = true;
      axios.post('/hotspot/voucher/device/', {
        voucher: this.voucherCode,
        mac_address: store.macAddress,
        provider: this.checkProvider(this.provider)
      })
        .then((response) => {
          if (response.status === 200) {
            this.getHotspotDevice(store.macAddress);
            this.hotspotMacLogin(store.macAddress);
          } else {
            console.error('Error:', response.data.message);
            vm.formErrors = response.data.message;
          }
          this.buttonLoading = false;
        })
        .catch((error) => {
          console.error('Request error:', error);
          this.buttonLoading = false;

          // Check if error.response exists before accessing data.message
          vm.formErrors = error.response ? error.response.data.message : error.message;
        });
    },
    onSubmit() {
      console.log('submit')
    },
    async getTheMacAddress() {
      let vm = this;
      console.log("macAddress from the store: " + store.macAddress);
      try {
        let response = await axios.get("eligibility/", {
          params: {
            mac_address: store.macAddress,
          },
        });
        if (response.status === 200) {
          vm.eligibleForDoubleUp = response.data.eligible;
        }
      } catch (error) {
        if (!error.response) {
          vm.connectionRefused = true;
        }
      }
    },
  },
  mounted() {
    this.getTheMacAddress()

  },
};
</script>

<template>
  <v-row>
    <v-col cols="12" sm="12" md="12" lg="10" xl="8" class="mx-auto">
        <!-- get the doubled up -->
        <v-img
        v-if="eligibleForDoubleUp"
        :src="hotspotDoubleUpPath"
        alt=""
        contain
      />

    </v-col>
  </v-row>
  <v-row v-if="!selectBundle" no-gutters>
    <v-col cols="12" sm="12" md="12" lg="10" xl="8" class="mx-auto">
      <div v-if="!connectionRefused">
        <p class="text-center text-darkRed pb-9 pl-3 pr-3 mt-n4" v-show="formErrors">
          <v-icon size="18" class="pb-1 pr-1"> mdi-alert </v-icon><span class="font-weight-bold">{{ formErrors }}</span>
        </p>
        <v-form ref="form" class="pt-2" lazy-validation @submit.prevent="onSubmit">
          <v-autocomplete
              class="support-field"
              prepend-inner-icon="mdi-magnify"
              variant="outlined"
              v-model="provider"
              :items="store.serviceProviders"
              :rules="[(v) => !!v || 'Voucher provider is required']"
              label="Voucher Provider"
              required
              filled
            ></v-autocomplete>
          <v-text-field prepend-inner-icon="mdi-ticket" variant="outlined" clearable v-model="voucherCode"
            :rules="voucherCodeRules" label="Enter Voucher Code" required @keyup.enter="validateInput"></v-text-field>
          <div class="button-container">
            <button class="submit-button" :disabled="buttonLoading" @click="validateInput">
              <span v-if="buttonLoading">
                <v-progress-circular :size="22" :width="2" class="text-pacificBlue" indeterminate></v-progress-circular>
              </span>
              <span v-else>connect</span>
            </button>
          </div>
          <div class="signup-info">
            <p>No voucher? <a href="/data-bundles">Buy data now</a></p>
            <ButtonComponent text="Signup now" primary link="/register" margin="2em 0" backgroundColor="#1899D1" color="#FFFFFF" width="100%" />
          </div>
        </v-form>
      </div>
      <div v-else>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="mx-auto text-center" style="padding-top:100px">
            <v-icon size="100">mdi-emoticon-sad-outline</v-icon>
            <p class="page-title" style="padding-bottom: 40px">Oops! Connection Lost</p>
            <p>Please check your internet connection and try again</p>
            <v-btn @click="connectionRefused = false" size="large" color="#0693e3"
              style="color:white; font-weight: 700; border-radius: 25px; margin-top: 50px">Try Again</v-btn>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
  <v-row v-if="selectBundle" no-gutters>
    <Bundles :voucher-code="voucherCode" />
  </v-row>
</template>


<style scoped>
.service-providers {
  display: flex;
  justify-content: space-between;
  padding: 1em;
}
.provider img {
  max-height: 3em;
}
.support-field {
  margin-bottom: 1em;
}
.load-voucher {
  padding-top: 70px;
  padding-bottom: 40px;
  font-size: 22px;
  font-weight: 600;
}

.button-container {
  display: flex;
}

.submit-button {
  width: 100%;
  background-color: #1899D1;
  color: white;
  border-radius: 3px;
  padding: 0.5em 0;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 1em;
  box-shadow: 0px 2px 4px 0px rgba(24, 153, 209, 0.20);
}

.banner-img {
  margin: 2em 0 1em;
}

.signup-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signup-info p {
  font-size: 1em;
  margin: 1em 0;
  font-weight: 500;
  color: #666666;
}

.signup-info a {
  color: #1899D1;
}

.banner-img img {
  width: 100%;
}

.signup-button {
  width: 100%;
  border: 1px solid #1899D1;
  color: white;
  border-radius: 3px;
  padding: 0.5em 0;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 1em;
  box-shadow: 0px 2px 4px 0px rgba(24, 153, 209, 0.22);
}

@media only screen and (min-width: 768px) {
  .submit-button {
    width: 12em;
    margin: 0 0 0 auto;
  }
}
</style>

<script>
import axios from "axios";
import { store } from "../store.js";

export default {
    props: { voucherCode: String },
    data() {
        return {
            store,
            dataBundles: null,
            unlimitedPackages: null,
            selectedBundle: null,
            selectedInstallation: { speed: "", price: "" },
            selectedType: "",
            dialog: false,
            snackbarResponse: null,
            snackbarType: null,
            openSnackBar: false,
            connectionRefused: false,
            voucherCode: this.voucherCode,
            loading: false
        }
    },
    methods: {
        async getDataBundles() {
            let vm = this;
            vm.loading = true
            let response = await axios
                .get("bundles/")
                .then(function (response) {
                    if (response.status === 200) {
                        vm.dataBundles = response.data.data_bundles;
                        vm.unlimitedPackages = response.data.unlimited_bundles;
                    }
                })
                .catch((error) => {
                    if (!error.response) {
                        vm.connectionRefused = true;
                    }
                    else {
                        vm.snackbarResponse = error.response.data.message
                        vm.snackbarType = "error"
                        vm.showSnackBar()

                    }
                });
            vm.loading = false

        },
        async submitVoucher(product = null) {


            this.buttonLoading = true;
            let vm = this;
            vm.loading = true
            vm.dialog = false
            let response = await axios
                .post("load-voucher/", {
                    voucher_code: vm.voucherCode,
                    product_id: product
                })
                .then(function (response) {
                    if (response.status === 200) {
                        vm.showSnackBar(200)
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        vm.formErrors = error.response.data.message;
                        vm.snackbarResponse = error.response.data.message
                        vm.snackbarType = "error"
                    } else {
                        vm.connectionRefused = true;
                    }
                    vm.buttonLoading = false;
                });
            this.loading = false
            this.showSnackBar()

        },
        getBundlePrice(price) {
            return Math.round(price * 1.15);
        },
        getBundleData(bundle) {
            const values = bundle.name.split(" / ");
            return values[1];
        },
        setSelectedBundle(id, type) {

            this.submitVoucher(id)
        },

        showSnackBar(code = null) {
            setTimeout((this.openSnackBar = true), 3000);
        },
    },
    mounted() {
        this.getDataBundles();
    },
};
</script>
<template>
    <v-col cols="12" sm="12" md="12" lg="6" xl="6" class="mx-auto">

        <div v-if="dataBundles && unlimitedPackages && !connectionRefused">

            <h3 class="bundle-title pt-16">Hotspot Unlimited Packages</h3>
            <div v-for="bundle in unlimitedPackages" :key="bundle.id" class="bundle-card"
                @click="setSelectedBundle(bundle.id, 'unlimited')">
                <v-row no-gutters class="bundle-position">
                    <v-col class="bundle-size text-center pa-3" cols="5">
                        {{ bundle.override_display_name }}
                    </v-col>
                    <v-col cols="7" class="text-center">
                        <v-icon class="bundle-icon">mdi-plus-circle</v-icon>
                        <div class="bundle-price">R{{ getBundlePrice(bundle.price_ex_vat) }}</div>
                        <div class="bundle-duration">Valid for {{ bundle.data_bundle_expiry_days }} days</div>
                    </v-col>
                </v-row>
            </div>

            <div class="text-center">
                <small class="text-center pt-2">Designed for use on only one device at a time.</small>
            </div>

        </div>
        <div v-else-if="connectionRefused">
            <v-row>
                <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="mx-auto text-center pt-16">
                    <v-icon size="100">mdi-emoticon-sad-outline</v-icon>
                    <p class="page-title pb-10">Oops! Connection Lost</p>
                    <p>Please check your internet connection and try again</p>
                    <v-btn @click="refreshPage" size="large" class="bg-pacificBlue text-white font-weight-bold mt-13"
                        rounded="lg">Try Again</v-btn>
                </v-col>
            </v-row>
        </div>

    </v-col>
    <v-row no-gutters class="position-relative">
        <div class="text-center">
            <v-snackbar :color="snackbarType" light="true" multi-line v-model="openSnackBar" timeout="1000"
                transition="dialog-bottom-transition">
                <span>{{ snackbarResponse }}</span>
                <template v-slot:actions>
                    <v-btn class="bg-pacificBlue text-white" variant="text" @click="openSnackBar = false">
                        Close
                    </v-btn>
                </template>
            </v-snackbar>
        </div>
    </v-row>
    <LoadingOverlay v-if="loading" />
</template>

<style>
.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
    transition: transform 0.2s ease-in-out;
}
</style>

<style scoped>
.selected {
    transform: scale(0.97) translateZ(0);
    box-shadow: 0 2px 4px black;
    background: #93d9ff;
    transition: transform 0.15s ease-in;
}

.optedIn {
    color: #76ccfa;
}

.bundle-title {
    padding: 7px;
    padding-bottom: 18px;
}

.bundle-card {
    margin-bottom: 15px;
    box-shadow: 1px 1px 3px grey;
    border-radius: 9px 0px 0px 9px;
}

.bundle-icon {
    position: absolute;
    top: 3px;
    right: 3px;
    color: #0693e3;
}

.unlimited-bundle-price {
    font-size: 18px;
    font-weight: 600;
    padding-top: 13px;
}

.bundle-price {
    font-size: 16px;
    font-weight: 600;
    padding-top: 4px;
}

.bundle-duration {
    font-size: 12px;
}

.bundle-size {
    background: #0693e3;
    color: white;
    border-radius: 9px 0px 0px 9px;
    font-size: 16px;
    font-weight: 600;
}

.unlimited-bundle-note {
    color: white;
    font-size: 18px;
    padding: 20px;

}

.payment-card {
    border: 1px solid white;
    padding-top: 15px;
    padding-bottom: 10px;
    margin-left: 15px;
    margin-right: 15px;
    border-radius: 10px;
}

.payment-option {
    display: inline-block;
    font-size: 16px;
    padding-right: 10px;
}

.panel-question {
    font-weight: 600;
    padding-bottom: 5px;
    padding-top: 20px;
}

.bundle-position {
    position: relative;
}

.dialog {
    position: fixed;
    bottom: 0;
    right: 0;
    top: auto;
    overflow-y: auto;
    background: #24292e
}
</style>
